import styled from 'styled-components';
import React, { useRef, useState } from 'react';
import { Tooltip } from '@veneer/core';

type TEllipsisTooltipProps = {
  id: string;
  value: string;
};

const EllipsisTooltip = (props: TEllipsisTooltipProps) => {
  const [renderTooltip, setRenderTooltip] = useState(false);
  const columnRef = useRef<HTMLElement>();

  const trackEllipsis = () => {
    const el = columnRef.current;
    console.log(el?.scrollWidth, el?.clientWidth);
    /* istanbul ignore next */
    const isEllipsis = el?.scrollWidth > el?.clientWidth;

    if (isEllipsis) {
      setRenderTooltip(true);
    } else {
      setRenderTooltip(false);
    }
  };

  const renderContent = () => {
    return (
      <Content
        data-testid={'ellipsis-tooltip-content'}
        ref={columnRef}
        onMouseEnter={() => trackEllipsis()}
        onMouseLeave={() => setRenderTooltip(false)}
      >
        {props.value}
      </Content>
    );
  };

  return (
    <Wrapper id={props.id}>
      {renderTooltip ? (
        <Tooltip
          arrow
          data-testid="tooltip"
          content={<p className="tooltipContent">{props.value}</p>}
          placement="bottom-start"
          portal
          useJsPositioning
        >
          {renderContent()}
        </Tooltip>
      ) : (
        <>{renderContent()}</>
      )}
    </Wrapper>
  );
};

export default EllipsisTooltip;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
`;

const Content = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px;
  color: #212121;

  .tooltipContent {
    font-family: var(--fontFamilyLight);
    font-size: 12px;
    line-height: 16px;
  }

  #jsTooltipPosition {
    white-space: normal;
    word-break: break-word;
  }

  #noTooltip {
    display: none;
  }
`;
