import moment from 'moment';
import AssetsProviderFactory from 'src/assets/AssetsProviderFactory';
import { dateFormat } from './constants';
import { JarvisAuthProvider } from '@jarvis/web-http';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import { storeStartRootComponent } from 'src/store/details/action';

type ManageMfeProps = {
  country?: string;
  language?: string;
  stack?: number;
  authProvider: JarvisAuthProvider;
  isWex?: boolean;
  demoEnabled?: boolean;
  apiPath?: any;
};

const localProps: ManageMfeProps = {
  language: 'en',
  country: 'US',
  stack: 1,
  authProvider: null,
  isWex: false,
  demoEnabled: false,
  apiPath: null,
};

export const setProps = () => {
  const { authProvider, localization, stack, isWex, demoEnabled, apiPath } = useShellRootContext();
  localProps.authProvider = authProvider;
  localProps.language = localization.language;
  localProps.stack = stack ?? 1;
  localProps.country = localization.country;
  localProps.isWex = isWex;
  localProps.demoEnabled = demoEnabled;
  localProps.apiPath = apiPath ?? null;
};

export const getProps = (): ManageMfeProps => {
  return localProps;
};

export const t = (subkey: string, args?: Record<string, string>): string => {
  const props = getProps();
  const assetsProvider = AssetsProviderFactory.create(
    props.language || 'en',
    props.country || 'US',
  );
  return assetsProvider.getText(`ecp-device-details.${subkey}`, args);
};

export const fetchImage = (images) => {
  let mostCleanImage = {
    url: 'https://devices.us1.api.ws-hp.com/devices/v1/images/undefined/1x1.png',
    width: 0,
    height: 0,
  };

  images.forEach((image) => {
    if (image?.url?.url !== undefined) {
      image.url = image?.url?.url;
    }

    const splitArray = image?.url?.split('/') ?? [];
    const imageSize = splitArray[splitArray.length - 1];
    const lastIndex = imageSize.lastIndexOf('.');
    const imageSizeWithoutExtension = imageSize.substring(0, lastIndex);
    const imageSizeArray = imageSizeWithoutExtension.split('x');
    const width = parseInt(imageSizeArray[0]);
    const height = parseInt(imageSizeArray[1]);

    if (width > mostCleanImage.width && height > mostCleanImage.height) {
      mostCleanImage = {
        url: image.url,
        width: width,
        height: height,
      };
    }
  });

  return mostCleanImage.url;
};

export const getCurrentDateTime = (format) => {
  return moment().format(format);
};

export const getFormattedDate = (format, date) => {
  if (date === undefined) return '--';
  moment.locale('en');
  return moment(date).format(format);
};

export const capitalizeFirstLetter = (str) => {
  return str.replace(/^./, str[0].toUpperCase());
};

export const refreshRootComponent = (dispatch) => {
  dispatch(storeStartRootComponent(false));

  setTimeout(() => {
    dispatch(storeStartRootComponent(true));
  }, 100);
};

export const isEmptyData = (data) => {
  if (data === null || data === undefined) {
    return true;
  }
  return data.length === 0;
};

export const convertCamelCaseToString = (input: string): string => {
  return input
    .replace(/([A-Z])/g, ' $1') // Insert space before each uppercase letter
    .replace(/\bOf\b/g, 'of') // Replace 'Of' with 'of' only if it's a standalone word
    .replace(/^\w/, (c) => c.toUpperCase()) // Capitalize the first letter
    .trim(); // Remove any leading or trailing spaces
};

export const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const refined = (data: any) => {
  if (data === undefined || data === null || data === '') {
    return '--';
  } else {
    return data;
  }
};

export const refinedDate = (data: any) => {
  moment.locale('en');
  if (data === undefined || data === null || data === '') {
    return '--';
  } else {
    if (moment(data).isValid()) return moment(data).format(dateFormat.default);
    else return '--';
  }
};

export const getMockUpData = async (moduleName: string) => {
  return await import(`src/mock/${moduleName}.json`);
};

export const isMockUpMode = () => {
  return !!(
    (process.env.NODE_ENV == 'development' || process.env.NODE_ENV == 'test') &&
    localStorage.getItem('enable-devices-mockup-data')
  );
};

export const getPermissionSet = (permission: any, permissionSet: any[]) => {
  for (const key in permission) {
    if (permission[key] instanceof Object) {
      getPermissionSet(permission[key], permissionSet);
    } else {
      permissionSet.push(permission[key]);
    }
  }
  return permissionSet;
};

export const getPathNameForWex = () => {
  if (window.location.pathname.startsWith('/ui/view')) return '/ui/view/devicesPrinter';
  return '/view/devicesPrinter';
};

export const getBaseName = () => {
  const { customRelativePath, navigation, isWex, isFromApps } = useShellRootContext();
  const baseurl = customRelativePath || '/';

  if (isWex) {
    return '';
  } else {
    if (navigation) {
      return navigation.createHref({ pathname: `${baseurl}${isFromApps ? '/devices' : ''}` });
    } else {
      return `${baseurl}${isFromApps ? '/devices' : ''}`;
    }
  }
};

export const getPathname = (isWex, deviceId, currentPath) => {
  if (isWex) {
    if (window.location.pathname.startsWith('/ui/fleet-management')) {
      return `/ui/fleet-management/devicesPrinter/${deviceId}`;
    } else if (window.location.pathname.startsWith('/ui/view/devices')) {
      return `/ui/view/devices/printers/${deviceId}`;
    } else if (window.location.pathname.startsWith('/ui/view')) {
      return `/ui/view/printers/${deviceId}`;
    }
    return `/fleet-management/devicesPrinter/${deviceId}`;
  }
  // ECP
  return currentPath;
};

export const getStatus = (status: {
  acceptingJobs: boolean;
  printerStateSeverity: string;
  printerState: string;
  connectionState: string;
}) => {
  if (!status || status === null || status === undefined) {
    return 'ERROR';
  }
  const acceptingJobs = status?.acceptingJobs;
  const printerStateSeverity = status?.printerStateSeverity;
  const printerState = status?.printerState;

  if (
    status.connectionState == 'offline' ||
    status.printerState == '--' ||
    status.printerStateSeverity == '--'
  ) {
    return 'UNKNOWN';
  } else if (
    (acceptingJobs === false && printerState !== 'PROCESSING') ||
    printerStateSeverity === 'ERROR'
  ) {
    return 'ERROR';
  } else if (printerStateSeverity === 'WARNING') {
    return 'WARNING';
  } else {
    return 'READY';
  }
};
