/* eslint-disable camelcase */

import de_DE from './de_DE.json';
import da_DK from './da_DK.json';
import en_US from './en_US.json';
import es_ES from './es_ES.json';
import fi_FI from './fi_FI.json';
import fr_FR from './fr_FR.json';
import it_IT from './it_IT.json';
import ja_JP from './ja_JP.json';
import ko_KR from './ko_KR.json';
import nb_NO from './nb_NO.json';
import nl_NL from './nl_NL.json';
import pl_PL from './pl_PL.json';
import pt_PT from './pt_PT.json';
import pt_BR from './pt_BR.json';
import ru_RU from './ru_RU.json';
import sv_SE from './sv_SE.json';
import zh_CN from './zh_CN.json';
import zh_TW from './zh_TW.json';

export default {
  da_DK,
  de_DE,
  en_US,
  es_ES,
  fi_FI,
  fr_FR,
  it_IT,
  ja_JP,
  ko_KR,
  nb_NO,
  nl_NL,
  pl_PL,
  pt_BR,
  pt_PT,
  ru_RU,
  sv_SE,
  zh_CN,
  zh_TW,
};
