import styled from 'styled-components';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Tabs } from '@veneer/core';
import { TabMfe } from 'src/components/atom';
import { useStoreState } from 'src/store/useStoreState';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import { t, getBaseName, getPathname } from 'src/utils/commonMethods';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

export const DeviceDetailsTab = (props) => {
  const baseName = getBaseName();
  const { detailsState, detailsRnPmState } = useStoreState();
  const { deviceId, isPartnerLinkSupported, isFromUnassigned, showJobsTab } = detailsState;
  const { featuresConstraintsReadRnPm, policyReadRnPm, policyListRnPm, jobHistoryReadRnPm } =
    detailsRnPmState;
  const {
    isWex,
    stack,
    shell,
    showJobs,
    showPolicy,
    navigation,
    showOverview,
    featureFlags,
    showConfiguration,
    customRelativePath,
    tenantId,
    tenantEmail,
  } = useShellRootContext();
  const [currentTab, setCurrentTab] = useState<number | undefined>(getCurrentTab());
  const currentDevicePath = useRef(navigation.location.pathname.split('/').slice(0, 3).join('/')); // ecp - 'devices/:deviceId'

  useEffect(() => {
    function tabHandler() {
      setCurrentTab(getCurrentTab());
    }
    if (!isWex) window.addEventListener('popstate', tabHandler);

    return () => {
      if (!isWex) {
        console.log('[details] cleanup');
        window.removeEventListener('popstate', tabHandler);
      }
    };
  }, []);

  const isSDSEventLogAllowed = () => {
    const allowedSDSTenants = featureFlags.enableSdsEventLogTenants.split(',');
    return allowedSDSTenants.some((tenant) => {
      return tenant === tenantEmail || tenant === tenantId;
    });
  };

  function getCurrentTab() {
    const baseurl = customRelativePath || '/';
    let pathArray;
    if (!isWex) {
      pathArray = navigation ? navigation.location.pathname.split('/') : baseurl.split('/');
    } else {
      pathArray = window ? window.location.pathname.split('/') : baseurl.split('/');
    }
    const tabPath = pathArray[pathArray.length - 1];

    switch (tabPath) {
      case 'sds-event-log':
        return 6;
      case 'fleet-manager':
        return 5;
      case `policy`:
        return 4;
      case `partnerlink`:
        return 3;
      case `configuration`:
        return 2;
      case 'jobs':
        return 1;
      default:
        return 0;
    }
  }

  const tabs = [];

  if (showOverview) {
    tabs.push({
      id: 0,
      label: t('label.overview'),
      title: 'Device Details: Overview',
      description: '',
      content: isWex ? (
        <TabMfe
          {...props}
          type="ECPDevicesDetailsOverview"
          component="@jarvis/react-ecp-device-details-overview"
        />
      ) : (
        <Route path="">
          <TabMfe
            {...props}
            type="ECPDevicesDetailsOverview"
            component="@jarvis/react-ecp-device-details-overview"
          />
        </Route>
      ),
    });
  }

  if (
    showJobs &&
    showJobsTab &&
    !isFromUnassigned &&
    jobHistoryReadRnPm &&
    featureFlags.enableJobHistory &&
    !isWex
  ) {
    tabs.push({
      id: 1,
      label: t('label.jobs'),
      title: 'Device Details: Jobs',
      description: '',
      content: (
        <Route path="(|/jobs)">
          <TabMfe
            {...props}
            type="ECPDevicesDetailsJobs"
            component="@jarvis/react-ecp-device-details-job-history"
          />
        </Route>
      ),
    });
  }

  if (showConfiguration && featuresConstraintsReadRnPm && !isFromUnassigned) {
    tabs.push({
      id: 2,
      label: t('label.properties'),
      title: 'Device Details: Configuration',
      description: '',
      content: isWex ? (
        <TabMfe
          {...props}
          type="ECPDevicesDetailsConfiguration"
          component="@jarvis/react-ecp-device-details-configuration"
        />
      ) : (
        <Route path="(|./configuration)">
          <TabMfe
            {...props}
            type="ECPDevicesDetailsConfiguration"
            component="@jarvis/react-ecp-device-details-configuration"
          />
        </Route>
      ),
    });
  }

  if (isPartnerLinkSupported && featureFlags.enablePartnerlink && !isWex) {
    tabs.push({
      id: 3,
      label: 'Partnerlink',
      title: 'Device Details: Partnerlink',
      description: '',
      content: (
        <Route path="(|/partnerlink)">
          <TabMfe
            {...props}
            type="ECPDevicesDetailsPartnerlink"
            component="@jarvis/react-ecp-device-details-partnerlink"
          />
        </Route>
      ),
    });
  }

  if (showPolicy && !isFromUnassigned && policyReadRnPm && policyListRnPm) {
    tabs.push({
      id: 4,
      label: t('label.policies'),
      title: 'Device Details: Policies',
      description: '',
      content: (
        <Route path="(|/policy)">
          <TabMfe
            {...props}
            type="ECPDevicesDetailsPolicy"
            component="@jarvis/react-ecp-policies"
          />
        </Route>
      ),
    });
  }

  tabs.push({
    id: 5,
    label: t('label.secure_fleet_manager', {
      solution: 'HP Secure Fleet Manager',
    }),
    title: 'Device Details: HP Secure Fleet Manager',
    description: '',
    content: (
      <TabMfe
        type="ECPDevicesDetailsFleetManager"
        component="@jarvis/react-ecp-endpointsecurity-devicespage"
        useMockData={false}
      />
    ),
  });

  if (isWex && (featureFlags.enableSdsEventLog || isSDSEventLogAllowed())) {
    tabs.push({
      id: 6,
      label: t('label.event_log'),
      title: 'Device Details: Event Log',
      description: '',
      content: (
        <TabMfe
          {...props}
          type="ECPDevicesDetailsSDSEventLog"
          component="@jarvis/react-sds-event-log"
        />
      ),
    });
  }

  const handleChangeTab = (id) => {
    const tabName = {
      0: '',
      1: '/jobs',
      2: '/configuration',
      3: '/partnerlink',
      4: '/policy',
      5: '/fleet-manager',
      6: '/sds-event-log',
    }[id];

    setCurrentTab(id);
    const newPath = `${getPathname(isWex, deviceId, currentDevicePath.current)}${tabName}`;
    window.history.pushState({}, '', newPath);
  };

  const memoRenderComponent = useMemo(() => {
    console.log('[details] render <DeviceDetails />');

    return (
      <Wrapper className="devicesMain" data-testid="device-details-tab">
        <Router basename={baseName}>
          <Switch>
            {tabs.length !== 0 && (
              <Tabs
                controlId="extended"
                mode="extended"
                onChangeTab={handleChangeTab}
                selectedTabId={currentTab}
                tabs={tabs}
                data-testid="devices-tabs"
                className={'details-tabs-list'}
              />
            )}
          </Switch>
        </Router>
      </Wrapper>
    );
  }, [shell, stack, props, currentTab]);

  return <>{memoRenderComponent}</>;
};

DeviceDetailsTab.defaultProps = {
  shell: {},
  stack: null,
};

DeviceDetailsTab.propTypes = {
  shell: PropTypes.objectOf(PropTypes.any),
  stack: PropTypes.number,
};

const Wrapper = styled.div`
  .details-tabs-list {
    span {
      display: flex;
      justify-content: center;
    }
  }
`;
